import React from 'react';
import { graphql } from 'gatsby';
import sanitizeHtml from 'sanitize-html';

import Background from '../components/Background';
import { LayoutSecondary } from '../components/LayoutSecondary';
import { LegalProps } from '../types';
import GlobalStyle from '../styles/globalStyles';

function LegalTextTemplate({ data }: LegalProps): JSX.Element {
  const { markdownRemark } = data;

  const { brandLogo, logoAlt } = markdownRemark.frontmatter;
  const content = markdownRemark.html;

  return (
    <>
      <GlobalStyle />
      <Background>
        <LayoutSecondary brandLogo={brandLogo} logoAlt={logoAlt}>
          {
            <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(content) }} /> // nosemgrep 
          }
        </LayoutSecondary>
      </Background>
    </>
  );
}

export const query = graphql`
  query LegalTextTemplate($id: String!) {
    markdownRemark(id: {eq: $id}) {  
      html
      frontmatter {
        brandLogo
        logoAlt
      }
    }
  }
`;

export default LegalTextTemplate;
