import React from 'react';
import styled from 'styled-components';

import { Header } from './Header';
import { LayoutSecondaryProps } from '../types';

const Wrapper = styled.div`
  padding: 15px 0;
  @media only screen and (min-width: 768px) {
    padding: 34px 60px;
  }
`;

const InnerWrapper = styled.div`
  padding: 15px;
  width: 100%;
`;

const Main = styled.main`
  padding: 1.25rem 1rem 2.5rem;
  margin-top: 1rem;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 0.25rem;
`;

export function LayoutSecondary({ children, brandLogo, logoAlt }: LayoutSecondaryProps): JSX.Element {
  return (
    <Wrapper>
      <InnerWrapper>
        <Header logoLink={brandLogo} logoAlt={logoAlt}/>
        <Main>
          {children}
        </Main>
      </InnerWrapper>
    </Wrapper>
  );
}
